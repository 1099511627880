@use 'sass:math';
@import "./src/styles/Library";
.card {
    background-color: $panelFooterBg;
    border: 2px solid $light1;
    //  outline: 3px solid $light1;
    margin-top: 4px;
    padding: 10px;
    border-radius: 5px;
    transition: .3s ease all;
}

.card:hover {
    background-color: rgba($primary, 8%);
    border-color: rgba($primary, 16%);
     .user a {
        color: $primary;
    }
}

.date {
    font-weight: 600;
    font-size: 1rem;
    a {
        color: $dark1;
    }
    a:hover {
        color: $alt !important;
    }
}

.style {
    a {
        color: $dark1;
    }
    a:hover {
        color: $alt !important;
    }
}

.remove {
    font-size: 16px;
    color: $dark4;
    display: inline-flex;
    svg {
        padding-right: 4px;
        top: 7px;
        position: relative;
    }
}
