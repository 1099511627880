
.status-grid {
    display: flex;
    gap: 4px;
}

.status {
    color: rgba($dark0, .6);
}

.status a {
    font-weight: 600;
    color: currentColor;
    text-decoration: underline;
}

/* CLASS ATTENDEE STATUS */
.status-attending {
    background-color: rgba($statusBlue, .6);
}
.status-attended {
    background-color: rgba($statusGreen, .8);
}
.status-noshow {
    background-color: rgba($statusRed, .6);
}
.status-cancelled {
    background-color: rgba($statusDarkOrange, .6);
}

/* ATTENDEE PASS STATUS */
.status-no-pass, .status-unpaid {
    background-color: rgba($statusRed, .6);
}
.status-has-pass, .status-has-credits {
    background-color: rgba($statusGreen, .8);
}
.status-retake {
    background-color: rgba($statusLightOrange, .6);
}

/* ATTENDEE ROLE */
.status-lead {
    background-color: rgba($lead, .9);
}
.status-follow {
    background-color: rgba($follow, .9);
}

/* PASS STATUS */
.status-active {
    background-color: rgba($statusGreen, .8);
}
.status-expired {
    background-color: rgba($statusRed, .6);
}
.status-used {
    background-color: rgba($statusLightOrange, .6);
}

/* CLASS TYPE */
.status-class {
    background-color: rgba($statusTeal, .6);
}
.status-socials {
    background-color: rgba($statusYellow, .6);
}


/* ORDER STATUS */
.status-cart {
    background-color: rgba($statusYellow, .6);
}
.status-shipping {
    background-color: rgba($statusBlue, .6);
}
.status-complete {
    background-color: rgba($statusGreen, .8);
}


/* ORDER PAYMENT STATUS */
.status-paid {
    background-color: rgba($statusTeal, .6);
}
.status-pending {
    background-color: rgba($statusGray, .6);
}


/* ORDER SHIPPING STATUS */
.status-packing {
    background-color: rgba($statusYellow, .6);
}
.status-packed {
    background-color: rgba($statusLightOrange, .6);
}
.status-shipped {
    background-color: rgba($statusBlue, .6);
}
.status-delivered {
    background-color: rgba($statusTeal, .6);
}
.status-returned {
    background-color: rgba($statusGray, .6);
}
