@use 'sass:math';
@import "./src/styles/Library";
.grid {
    display: flex;
    gap: 10px;
}

.card {
    position: relative;
    background: white;
    border-radius: 5px;
    width: 19%;
    text-align: center;
    border: 1px solid $light2;
    overflow: hidden;
    min-width: 150px;
    flex-basis: 20%;
    flex-grow: 1;
}

@media #{$tablet-landscape-down} {

    .grid {
        flex-wrap: wrap;
    }

    .card:first-child {
        flex-basis: 48%;
    }
    .card:nth-child(2) {
        flex-basis: 48%;
    }

}

@media #{$mobile-only} {

    .card {
        flex-basis: 48%;
    }
    .card:first-child {
        flex-basis: 100%;
    }

}

.body{
    margin-top: 10px;
    background-color: white;
    padding: 8px 12px;
}

.title {
    font-size: 17px;
    font-weight: bold;
}

.subtitle {
    font-size: 12px;
    color: #666;
}

.footer{
    background-color: $panelFooterBg;
    padding: 8px 12px;
}

.label {
    font-size: 15px;
    font-weight: bold;
}
