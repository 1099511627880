@use 'sass:math';
@import "./src/styles/Library";
.loading {
    text-align: center;
    width: 100%;
    margin: 40px;
}


.container :global {
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;

    .fc {
        flex-grow: 1;
        margin: 0 auto;
        background: $light3;
        padding: 8px;
        border-radius: 8px;
    }

    .fc-header-toolbar {
        all: unset;
        align-items: center;
        display: flex;
        justify-content: space-between;

        .fc-toolbar-title {
            font-size: 1.4rem;
            color: $dark1;
        }
    }

    .fc-icon{
        color: $dark1;
    }
    .fc-scrollgrid {
        border: none;
    }

    .fc-theme-standard,
    th {
        border: none;
    }

    .fc-scrollgrid-section-header {
        all: unset;
        background: transparent;
    }

    .fc-day {
        font-size: 1rem;
        font-weight: 600;
        color: $dark1;
    }

    .fc-col-header-cell a {
        color: currentColor;

    }

    .fc-theme-standard td,
    .fc-theme-standard th {
        border: unset;
    }

    .fc-daygrid-day-top {
        justify-content: center;
    }

    .fc-daygrid-day {
        background: $light0;
    }

    .fc-day-other {
        background: $light1;
    }

    .fc-day-today {
        background: lighten($alt, 60%) !important;
        color: $alt;
    }

    .fc .fc-day {
        border-left: 2px solid $light3;
        border-right: 2px solid $light3;
    }

    .fc .fc-day a {
        color: currentColor;
    }

    .fc-event {
        background: transparent !important;
        display: block;
        padding: 0;
        box-shadow: none !important;
    }
    .fc-event:before, .fc-event:after{
        background: transparent !important;
        box-shadow: none !important;
    }

    .fc .fc-daygrid-day-frame {
        min-height: 140px;
        height: auto;
    }

    .fc-button {
        background: none;
        border-color: transparent;
    }

    .fc-button:hover {
        background: none;
        border-color: transparent;
    }

    .fc-scrollgrid-sync-table {

        border-collapse:separate !important;

        tbody {
            tr:nth-child(1) {
                td {
                    border-radius: 8px 8px 0 0 !important;
                }

            }

            tr:nth-last-child(1) {
                td {
                    border-radius: 0 0 8px 8px !important;
                }
            }

            tr:not(:first-child) {
                .fc-daygrid-day-top {
                    border-top: 1px solid $light2;
                }
            }
        }
    }

}

.sep {
    text-align: center;
    margin: 10px 0 4px;
    width: 100%;
    .icon {
        max-width: 90%;
        background-color: transparent !important;
    }
}


.item {
    width: 100%;
    padding: 3px 3px;
    margin: 1px 0;
    background-color: $light2;
    color: rgba($dark1, .9);
    border-radius: 3px;
    cursor: pointer;
    font-weight: 700;

    &:hover {
        background-color: $accent !important;
        color: $light0;
    }

    .inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .innerCenter {
        justify-content: center;
    }

    .title {
        // @extend .text-meta-600;
    }

    .time {
        // @extend .text-meta-400;
    }
}

.item-type-class {
    background-color: $statusTeal;
}

.item-type-social {
    background-color: $statusPurple;
}
.item-type-practice {
    background-color: $statusYellow;
}
