@use 'sass:math';
@import "./src/styles/Library";
.card :global .panel-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photo {
    margin-bottom: .5rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $light1;
}

.name {
    font-size: 1.25rem;
    font-weight: 600;
}