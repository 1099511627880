@use 'sass:math';
@import "./src/styles/Library";

.wrap {
    position: relative;
    min-width: 120px;
     &:hover .tooltip {
        display: block;
    }
}

//Bar

.bar {
    width: 100%;
    height: 32px;
    background-color: $light2;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    position: relative;
    justify-content: space-between;
}

.segment {
    height: 100%;
    position: relative;
    min-width: 0px;
    transition: .3s all ease-in;
    min-width: 6px;
}

.segment0 {
    min-width: 0px;
}

.bgFollow {
    background-color: $follow;
    position: relative;
}

.bgLead {
    background-color: $lead;
    position: relative;
}

.bgSolo {
    background-color: $solo;
    position: relative;
}

.count {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    color: rgba($dark0, .75);
    font-size: 11px;
    white-space: nowrap;
    background: rgba($light0, .92);
    padding: 0px 3px;
    border-radius: 3px;

}

.bgLead .count {
    right: 8px;
    left: inherit;
}

.retakeFollow {
    height: 100%;
    background-color: $follow;
    background-image: url("../../assets/retake.png");
    background-repeat: repeat;
    position: absolute;
    bottom: 0;
    transition: .3s all ease-in;
}

.retakeLead {
    height: 100%;
    background-color: $lead;
    background-image: url("../../assets/retake.png");
    background-repeat: repeat;
    position: absolute;
    bottom: 0;
    transition: .3s all ease-in;
    right: 0;
}

.noShow
{
    min-width: 0px;
    height: 5px;
    background-color: $noShow;
    position: absolute;
    bottom: 0;
    z-index: 1;
    transition: .3s all ease-in;
}

.bgLead .noShow {
    right: 0;
}