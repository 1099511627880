

.add-attendee-form {
    margin-top: -1.5rem;
}


@media #{$mobile-only} {

    .add-attendee-form .col-search {
        width: 100%;
    }

    .add-attendee-form .col-role {
        flex-grow: 1;
    }

    .add-attendee-form .col-role .form-field {
        padding-top: 0 !important;
        margin-top: -.75rem;
    }

    .add-attendee-form .col-add .form-field {
        padding-top: 0 !important;
        margin-top: -.75rem;
    }

}