@use 'sass:math';
@import "./src/styles/Library";
// heading block
.heading {
    text-transform: capitalize;
    padding-bottom: 5px;
}

.subHeading {
    text-transform: capitalize;
}

// image block
.imageText {
    padding: 10px;
}

// ContentContentSlider
.pad {
    padding-bottom: 5px;
}

.contentText {
    padding: 10px 10px;
    margin-bottom: 10px;
    background-color: $dark0;
    border-top: 1px solid $primary;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.contentTextHead {
    color: $primary;
}

.contentTextDesc {
    color: $light0;
}

.colWrap {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

// checklist
.checkWrap {
    border-radius: 9px;
    border: 1px solid #F6DE00;
    padding: 10px;
    background-color: rgba(#F6DE00, 0.2);
}

.headingCheck {
    padding-bottom: 5px;
    text-transform: uppercase;
}

.footerCheck {
    padding-top: 5px;
}

// spotlight
.spotCol {
    padding-bottom: 10px;
}

// static component
.staticCmp {
    background-color: rgba(#31bdf5, 0.2);
    border-radius: 9px;
    border: 1px solid #31bdf5;
    padding: 10px;
}


$lightgray: #e5e5e5;


//accordion
.accordion {

  .accordionItem {
    border-bottom: 1px solid $lightgray;
    button[aria-expanded='true'] {
      border-bottom: 1px solid $primary;
    }
  }
  button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1.4em 0;
    border: none;
    background: none;
    outline: none;
    &:hover, &:focus {
      cursor: pointer;
      &:hover {
        color: $primary;
      }
      &:focus::after {
        border: 1px solid $primary;
      }
      &::after {
        cursor: pointer;
      }
    }
    .heading {
      font-family: "Titillium Web", sans-serif;
      font-size: 13px;
      padding: .2em 1.5em .2em 0;
    }

    .subHeading {
      font-family: "Titillium Web", sans-serif;
      font-size: 13px;
      padding: .2em 1.5em .2em 0;
      color: $dark1;
    }
    .icon {
      display: inline-block;
      position: absolute;
      top: 20px;
      right: 0;
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-radius: 22px;
      &::before {
        display: block;
        position: absolute;
        content: '';
        top: 9px;
        left: 5px;
        width: 12px;
        height: 2px;
        background: currentColor;
      }
      &::after {
        display: block;
        position: absolute;
        content: '';
        top: 5px;
        left: 10px;
        width: 2px;
        height: 10px;
        background: currentColor;
      }
    }
  }
  button[aria-expanded='true'] {
    color: $primary;
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordionContent {
      opacity: 1;
      max-height: 50em;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }
  .accordionContent {

    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
    p {
      margin: 2em 0;
      font-family: "Titillium Web", sans-serif;
      font-size: 13px;
    }
  }
}


//menuItems

.container {
    background: $light1;
    border: 1px solid $dark1;
    border-radius: 7px;
    max-width: 720px;
    width: 100%;
    height: 90px;
    box-sizing: border-box;
    padding: 20px;
    gap: 15px;
    transition: .3s ease all;

    .link{
         text-decoration: none;
         color: 'inherit';
         display: flex;
         align-items: center;
    }

    .textContainer {
        .heading {
            font-family: "Titillium Web", sans-serif;
            font-size: 13px;
            color: $dark1;
        }

        .subHeading {
            font-family: "Titillium Web", sans-serif;
            font-size: 13px;
            color: $dark1;
        }
    }

    .svgContainer {
        margin-left: auto;
        color: $dark1;

        svg {
            width: 21px;
            height: 21px;
        }
    }

}

.container:hover {
    background: $primary;

    .textContainer {
        .heading {
            color: $light1;
        }

        .subHeading {
            color: $light1;
        }
    }

    .svgContainer {
        color: $light1;
    }

}