@use 'sass:math';
@import "./src/styles/Library";
/* App */

/*
    Project customizations here
    Overides default dash styles
*/

#sidebar #sidebar-menu h5 {
    font-weight: 600;
    color: rgba($light0, .8);
}

#sidebar #sidebar-menu a {
    padding-top: 3px;
    padding-bottom: 3px;
}

#ribbon .zones  a.active {
    background: linear-gradient(90deg, rgba($sidebarBg, 0.05) 0%, rgba($sidebarBg, 0.15) 100%);
}

.page-tabs .mdc-tab {
    padding-left: 20px;
    padding-right: 20px;
}

.meta-table dt {
    font-weight: 700;
    color: $dark2;
}

.meta-table dd {
    padding-bottom: 4px;
    color: $dark0;
}

.form-field .form-field-label {
    font-weight: 700;
    color: $dark2;
    font-size: 13px;
}

.table-bg-gray {
    background-color: rgba($light1, .5) !important;
}

.table-bdr-left-gray {
    border-left: 2px solid $light3 !important;
}

html .panel {
    box-shadow: none;
    outline: 3px solid rgba($light3, .2);
}


.sortable-item {
    background-color: $light1;
    border: 1px solid $light2;
    border-radius: 3px;
}

.sortable-item:hover, .form-field-list-item-draging .sortable-item {
    background-color: rgba($secondary, 12%);
    border: 1px solid $secondary;
    border-radius: 3px;
}


.status-dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: $light2;
    display: inline-block;
    margin-right: 2px;
}

.bg-yellow {
    background-color: #F7E49A !important;
}

.bg-gold {
    background-color: #F7D08A !important;
}

.bg-peach {
    background-color: #FECE79 !important;
}

.bg-orange {
    background-color: #F8A656 !important;
}

.bg-salmon {
    background-color: #F48170 !important;
}

.bg-pink {
    background-color: #F38193 !important;
}

.bg-red {
    background-color: #FF6060 !important;
}

.bg-purple {
    background-color: #8B8BC3 !important;
}

.bg-mauve {
    background-color: #bc86ad !important;
}

.bg-brown {
    background-color: #8e6a6a !important;
}

.bg-blue {
    background-color: #94CAE3 !important;
}

.bg-teal {
    background-color: #A0D9D9 !important;
}

.bg-green {
    background-color: #97D1A9 !important;
}

.bg-mint {
    background-color: #a7fcdb !important;
}

.bg-gray {
    background-color: #a0b0bb !important;
}

.bg-black {
    background-color: #000000 !important;
}
