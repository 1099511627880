@use 'sass:math';
@import "./src/styles/Library";
.legend {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 8px;
}

.indicator {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    line-height: 1.1em;
    font-size: 1.1rem;
}

.indicator :global span {
    font-size: .9rem;
}

.indicatorColor {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    margin-right: 4px;
    border-radius: 3px;
}

.soloColor{
    background-color: $solo;
}

.followColor{
    background-color: $follow;
}

.followRetakeColor{
    background-color: $follow;
    background-image: url("../../assets/retake.png");
    background-repeat: repeat;
    background-position: center center;
    background-size: 24px;
}

.leadColor{
    background-color: $lead;
}

.leadRetakeColor{
    background-color: $lead;
    background-image: url("../../assets/retake.png");
    background-repeat: repeat;
    background-position: center center;
    background-size: 24px;
}

.retakeColor{
    background-color: $light1;
    background-image: url("../../assets/retake.png");
    background-repeat: repeat;
    background-position: center center;
    background-size: 24px;
}

.noShowColor{
    background-color: $noShow;
}