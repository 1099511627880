@use 'sass:math';
@import "./src/styles/Library";
.balance {
    font-size: .9rem;
    font-weight: 500;
    font-weight: 600;
    padding: 4px .8rem 4px .5rem;
    background-color: $light0;
    color: $statusTeal;
    border: 2px solid currentColor;
    border-radius: 32px;
    display: flex;
    justify-content: center;
}

.negative {
    color: $statusRed;
}

.empty {
    color: $statusDarkOrange;
}

.icon {
    font-size: 1.4rem;
    color: currentColor;
    transform: translateY(-1px);
}