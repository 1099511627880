@use 'sass:math';
@import "./src/styles/Library";
.menu {
    width: 1280px;
    max-width: 100%;
    margin: auto;
}

.logo img {
    max-width: 100%;
    width: 400px;
    margin: 0 auto;
    display: block;
}

.zones {
    display: flex;
    grid-gap: 1rem;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.zone {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 120px;
    height: 120px;
    flex-grow: 1;
    background: $panelBg;
    box-shadow: 0 0 1rem 0 rgba($panelBdr, .6);
    border-radius: 3px;
    box-sizing: border-box;
    text-align: center;
    transition: .3s ease all;
    outline: 1px solid transparent;
}

.zone i {
    display: block;
    text-align: center;
    margin-bottom: .8rem;
}

.zone span {
    display: block;
    position: absolute;
    bottom: 20px;
    font-size: 1rem;
    color: $primaryDark;
    font-weight: 600;
    transition: color 0.2s ease;
}

.zone:hover {
    outline: 1px solid rgba($primary, 1);
    box-shadow: 0 0 .8rem 0 rgba($primary, .5);
}

.zone:hover span {
    color: $primary;
}

@media #{$mobile-only} {
    .zone {
        width: 100px;
        height: 100px;
    }
}
