@use 'sass:math';
@import "./src/styles/Library";
.card {
    background-color: $panelFooterBg;
    border: 2px solid $light1;
    //  outline: 3px solid $light1;
    margin-top: 4px;
    padding: 10px;
    border-radius: 5px;
    transition: .3s ease all;
}

.card:hover {
    background-color: rgba($primary, 8%);
    border-color: rgba($primary, 16%);
     .user a {
        color: $primary;
    }
}