@use 'sass:math';
@import "./src/styles/Library";
.wrap {
    width: 32px;
    height: 32px;
    border-radius: 3px;
    background-color: $light1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.bgLead {
    background-color: $lead;
}
.bgFollow {
    background-color: $follow;
}
.bgSolo {
    background-color: $solo;
}

.bgLeadRetake {
    background-color: $lead;
    background-image: url("../../assets/retake.png");
    background-repeat: repeat;
}
.bgFollowRetake {
    background-color: $follow;
    background-image: url("../../assets/retake.png");
    background-repeat: repeat;
}
.bgSoloRetake {
    background-color: $solo;
    background-image: url("../../assets/retake.png");
    background-repeat: repeat;
}

.bgNoShow {
    background-color: $noShow;
}

.badgeSelfService {
    position: absolute;
    background: $light0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    top: 0;
    left: 0;
    transform: translate(-30%, -30%);
}

.badgePostPaid {
    position: absolute;
    background: $light0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    bottom: 0;
    right: 0;
    transform: translate(30%, 30%);
}
.badgeUnPaid {
    position: absolute;
    background: $light0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    bottom: 0;
    right: 0;
    transform: translate(30%, 30%);
}