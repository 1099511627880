@use 'sass:math';
@import "./src/styles/Library";
.card {
    background-color: $panelFooterBg;
    border: 2px solid $light1;
    //  outline: 3px solid $light1;
    margin-top: 4px;
    padding: 10px;
    border-radius: 5px;
    transition: .3s ease all;
}

.card:hover {
    background-color: rgba($secondary, 12%);
    border-color: $secondary;
     .user a {
        color: $primary;
    }
}

.colName :global .flex-inner {
    display: flex;
    align-items: center;
}

.user {
    font-weight: 600;
    font-size: 1.05rem;
    line-height: 1.1;
    a {
        color: $dark1;
    }
    a:hover {
        color: $alt !important;
    }
    span {
        font-size: .9rem;
        font-weight: 400;
    }
}

.remove {
    font-size: 16px;
    color: $dark4;
    display: inline-flex;
    svg {
        padding-right: 4px;
        transform: translateY(1px);
    }
}

.remove:hover {
    color: $statusWarn;
}

@media #{$tablet-portrait-up} {

    .colName {
        order: 0;
    }
    .colInfo{
        order: 1;
    }
    .colEdit {
        order: 2;
    }
    .colActions {
        order: 3;
        width: auto;
    }

}
