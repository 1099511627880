// Overwrite Variables Here

// $regular: "Aptos Narrow", sans-serif;

$brandGreen: #36B44A;
$brandBlue: #59A1F5;
$brandGreenDark: #0F9D46;
$brandBlueDark: #397AC0;

$primary: $brandBlueDark;
$secondary:$brandBlue;
$alt:$brandGreen;
$accent:$brandGreen;

//00C081

$primaryLight: #fffae8;
$primaryDark: #1e1b14;

$sidebarBg: #222327;
$ribbonBg: #397AC0;

// $btnPrimaryText: $primaryDark;
// $btnHoverBg: $light0;
// $btnHoverBdr: $primary;
// $btnHoverText: $primaryDark;

$fieldBdr : $light3;

/* Define status color variables */
$statusYellow: #FFF38B;
$statusPink: #FF7DCA;
$statusPurple: #D899FC;
$statusLightOrange: #D2B29D;
$statusDarkOrange: #FFB155;
$statusGreen: #98F8AA;
$statusTeal: #3ccbb8;
$statusBlue: #77CBFF;
$statusGray: #CCCCCC;
$statusRed: #FF7276;
$statusWarn: #FF0000;

/* Define attendance color variables */
$lead: #8dbbef;
$follow: #78c688;
$solo: #d3aae4;
$leadRetake: darken($lead, 10%);
$followRetake: darken($follow, 10%);
$soloRetake: darken($solo, 10%);
$noShow: rgba($statusRed, 80%);